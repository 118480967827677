'use strict'

###*
 # @ngdoc object
 # @name mundoBootstrap
 # @description

###
angular
  .module 'mundoBootstrap', []

$ng = angular.injector(['ng'])
$q = $ng.get('$q')
$http = $ng.get('$http')
$document = $ng.get('$document')
$log = $ng.get('$log')
configurationFile = '../config/main.json'
applicationName = 'mundomosa'
timestamp = Math.round((new Date()).getTime() / 1000)
counter = 0

loadConfiguration = () ->
  $http
    .get "#{configurationFile}?t=#{timestamp}"
    .then (response) ->
      config =
        application:
          homeState: 'home'
          applicationName: 'MundoSalsa'

      config = angular.merge {}, config, response.data
      baseUrl = if config.oauth? and config.oauth.baseUrl? then config.oauth.baseUrl else null
      tenantCode = if config.tenant? and config.tenant.code? then config.tenant.code else null

      loadTenantConfiguration(baseUrl, tenantCode)
        .then (tenantConfiguration) ->
          config = angular.merge {}, config, tenantConfiguration
          angular
            .module 'mundoBootstrap'
              .constant 'mundoConfiguration', config

          $log.debug 'Bootstrap: Configuration loaded', config

          # Dynamically loaded angular modules
          modules = if config.modules? then config.modules else {}

          for name, files of modules
            $log.debug "Bootstrap: Dynamically loading module '#{name}'"
            angular.module(applicationName).requires.push name

            for file in files
              counter++

              script = document.createElement 'script'
              script.src = "/resources/modules/#{name}/#{file}?t=#{timestamp}"
              script.onload = () ->
                counter--

              document.body.appendChild script

          attemptBootstrap()

loadTenantConfiguration = (baseUrl, tenantCode) ->
  return $q (resolve, reject) ->
    if not (tenantCode and baseUrl)
      resolve {}
      return

    $log.debug "Bootstrap: Loading configuration for tenant '#{tenantCode}'"

    $http
      .get "#{baseUrl}/api/open/services/tenant_configuration/#{tenantCode}?t=#{timestamp}"
      .then (response) ->
        resolve response.data
      , (error) ->
        resolve {}

attemptBootstrap = () ->
  if counter > 0
    setTimeout attemptBootstrap, 50
  else
    angular
      .element $document
        .ready () ->
          $log.debug 'Bootstrap: Bootstrapping'
          angular
            .bootstrap $document.find('html'), [applicationName]
    , (errorResponse) ->
      $log.error 'Bootstrap: Could not load configuration', errorResponse

loadConfiguration()
